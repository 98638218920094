/* IMAGE LIGHTBOX SELECTOR */

#imagelightbox {
    cursor: pointer;
    position: fixed;
    z-index: 10000;
    touch-action: none;
    box-shadow: 0 0 3.125em rgba(0, 0, 0, .75); /* 50 */
}

/* WITH ACTIVITY INDICATION */

#imagelightbox-loading,
#imagelightbox-loading div {
    border-radius: 9999px;
}

#imagelightbox-loading {
    background-color: rgb(128, 128, 128);
    background-color: rgba(0, 0, 0, .5);
    position: fixed;
    z-index: 10003;
    top: 50%;
    left: 50%;
    padding: 0.65em;
    margin: -1.3em 0 0 -1.3em;
    box-shadow: 0 0 2.5em rgba(0, 0, 0, .75); /* 40 */
}

#imagelightbox-loading div {
    width: 1.3em;
    height: 1.3em;
    background-color: #fff;
    animation: imagelightbox-loading .5s ease infinite;
}

@keyframes imagelightbox-loading {
    from {
        opacity: .5;
        transform: scale(.75);
    }
    50% {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: .5;
        transform: scale(.75);
    }
}

/* WITH OVERLAY */

#imagelightbox-overlay {
    background-color: #fff;
    background-color: rgba(255, 255, 255, .9);
    position: fixed;
    z-index: 9998;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

/* WITH "CLOSE" BUTTON */

#imagelightbox-close {
    width: 2.5em; /* 40 */
    height: 2.5em; /* 40 */
    background-color: #666;
    position: fixed;
    z-index: 10002;
    top: 2.5em; /* 40 */
    right: 2.5em; /* 40 */
    border-radius: 50%;
    transition: color .3s ease;
}

#imagelightbox-close:hover {
    background-color: #111;
}

#imagelightbox-close:before,
#imagelightbox-close:after {
    width: 2px;
    background-color: #fff;
    content: '';
    position: absolute;
    top: 20%;
    bottom: 20%;
    left: 50%;
    margin-left: -1px;
}

#imagelightbox-close:before {
    transform: rotate(45deg);
}

#imagelightbox-close:after {
    transform: rotate(-45deg);
}

/* WITH CAPTION */

#imagelightbox-caption {
    text-align: center;
    color: #fff;
    background-color: #666;
    position: fixed;
    z-index: 10001;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.625em; /* 10 */
}

/* WITH NAVIGATION */

#imagelightbox-nav {
    background-color: #444;
    background-color: rgba(0, 0, 0, .5);
    position: fixed;
    z-index: 10001;
    left: 50%;
    bottom: 3.75em; /* 60 */
    padding: 0.313em; /* 5 */
    transform: translateX(-50%);
    border-radius: 20px;
}

#imagelightbox-nav a {
    width: 1em; /* 20 */
    height: 1em; /* 20 */
    border: 1px solid #fff;
    display: inline-block;
    margin: 0 0.313em; /* 5 */
    border-radius: 50%;
}

#imagelightbox-nav .active {
    background-color: #fff;
}

/* WITH ARROWS */

.imagelightbox-arrow {
    width: 3.75em; /* 60 */
    height: 7.5em; /* 120 */
    background-color: #444;
    background-color: rgba( 0, 0, 0, .5 );
    vertical-align: middle;
    display: none;
    position: fixed;
    z-index: 10001;
    top: 50%;
    margin-top: -3.75em; /* 60 */
}
.imagelightbox-arrow:hover,
.imagelightbox-arrow:focus {
    background-color: #666;
    background-color: rgba( 0, 0, 0, .75 );
}
.imagelightbox-arrow:active {
    background-color: #111;
}
.imagelightbox-arrow-left {
    left: 2.5em; /* 40 */
}
.imagelightbox-arrow-right {
    right: 2.5em; /* 40 */
}
.imagelightbox-arrow:before {
    width: 0;
    height: 0;
    border: 1em solid transparent;
    content: '';
    display: inline-block;
    margin-bottom: -0.125em; /* 2 */
}
.imagelightbox-arrow-left:before {
    border-left: none;
    border-right-color: #fff;
    margin-left: -0.313em; /* 5 */
}
.imagelightbox-arrow-right:before {
    border-right: none;
    border-left-color: #fff;
    margin-right: -0.313em; /* 5 */
}

#imagelightbox-loading,
#imagelightbox-overlay,
#imagelightbox-close,
#imagelightbox-caption,
#imagelightbox-nav,
.imagelightbox-arrow {
    animation: fade-in .25s linear;
}

@keyframes fade-in {
    from	{ opacity: 0; }
    to		{ opacity: 1; }
}

@media only screen and (max-width: 41.250em) { /* 660 */
    #container {
        width: 100%;
    }
    #imagelightbox-close {
        top: 1.25em; /* 20 */
        right: 1.25em; /* 20 */
    }
    #imagelightbox-nav {
        bottom: 1.25em; /* 20 */
    }
    .imagelightbox-arrow {
        width: 2.5em; /* 40 */
        height: 3.75em; /* 60 */
        margin-top: -2.75em; /* 30 */
    }
    .imagelightbox-arrow-left {
        left: 1.25em; /* 20 */
    }
    .imagelightbox-arrow-right {
        right: 1.25em; /* 20 */
    }
}

@media only screen and (max-width: 20em) { /* 320 */
    .imagelightbox-arrow-left {
        left: 0;
    }
    .imagelightbox-arrow-right {
        right: 0;
    }
}